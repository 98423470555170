import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, SubletInvoiceActions, SubletInvoiceMutations } from './types';
//import { UserGetters } from '@/shared/store/user/types';
import SubletInvoiceService from '@/shared/services/SubletInvoiceService';
import state from '../state';
//import moment from 'moment';

const _actions = namespaceHelper.removeNamespace(namespace, SubletInvoiceActions);
const _mutations = namespaceHelper.removeNamespace(namespace, SubletInvoiceMutations);

export default {
  // Search
  async [_actions.SEARCH_SUBLET_INVOICES]({ commit }, parameters) {
    let response = {};
    response = await SubletInvoiceService.searchSubletInvoices(parameters);

    const searchResults = response.data;

    await commit(_mutations.SET_SUBLET_INVOICES, { searchResults, parameters });

    return response.data;
  },
  async [_actions.FETCH_SUBLET_INVOICE]({ commit }, parameters) {
    const response = await SubletInvoiceService.fetchSubletInvoice(parameters);
    await commit(_mutations.SET_SUBLET_INVOICE, response.data);
    return response.data;
  },
  async [_actions.SAVE_SUBLET_INVOICE](_, invoice) {
    await SubletInvoiceService.saveSubletInvoice(invoice);
  },
  async [_actions.APPROVE_SUBLET_INVOICE](_, invoice) {
    await SubletInvoiceService.approveSubletInvoice(invoice);
  },
  async [_actions.REJECT_SUBLET_INVOICE](_, invoice) {
    await SubletInvoiceService.rejectSubletInvoice(invoice);
  },
  async [_actions.SEND_SUBLET_INVOICE](_, invoice) {
    await SubletInvoiceService.sendSubletInvoice(invoice);
  },
  async [_actions.ADD_NEW_LINE_ITEM]({ commit }, params) {
    let newItemInt = parseInt(params.previousItemNumber) + 10;
    params.newItemNumber = newItemInt.toString().padStart(6, '0');
    commit(_mutations.SET_NEW_LINE_ITEM, params);
  },
  async [_actions.REMOVE_LINE_ITEM]({ commit, state }, index) {
    commit(_mutations.REMOVE_LINE_ITEM, index);
    if (index < state.invoice.length) {
      commit(_mutations.UPDATE_LINE_ITEM_NUMBERS);
    }
  },
  async [_actions.REMOVE_SUBLET_INVOICE]({ commit }, index) {
    commit(_mutations.REMOVE_INVOICE, index);
  },
  async [_actions.VALIDATE_FULL_SUBLET_INVOICE](_, invoice) {
    await SubletInvoiceService.validateFullSubletInvoice(invoice);
  },
  async [_actions.VALIDATE_HEADER_SUBLET_INVOICE](_, invoice) {
    await SubletInvoiceService.validateHeaderSubletInvoice(invoice);
  },
  async [_actions.DOWNLOAD_SUBLET_INVOICE_PDF](_, params) {
    let result = await SubletInvoiceService.fetchDownload(params);
    return result.data;
  },
  async [_actions.FETCH_SUBLET_INVOICES_COUNT]({ commit }) {
    let url = state.url;
    var isFire = false;
    if (url.includes('localhost:8050') || url.includes('clarkefire.com')) {
      isFire = true;
    }
    const count = await SubletInvoiceService.getSubletInvoicesCount(isFire);
    commit(_mutations.SET_SUBLET_INVOICES_COUNT, count.data);
  }
};
