import axios from 'axios';

const SUBLET_INVOICE_PATH = '/api/sublet-invoice';
const SEARCH_PATH = '/search';

const SAVE_PATH = '/save';
const APPROVE_PATH = '/approve';
const REJECT_PATH = '/reject';
const SEND_PATH = '/send';
const VALIDATE_PATH = '/validate';
const DOWNLOAD_PATH = '/download';
const INVOICES_COUNT_PATH = '/sublet-invoices-count';

class SubletInvoiceService {
  async searchSubletInvoices(parameters) {
    // if (parameters.category === 'history' || parameters.category === 'review' || parameters.category == 'reassign') {
    //   const url = `${SUBLET_INVOICE_PATH}${SEARCH_PATH}/${encodeURIComponent(parameters.category)}`;
    //   const invoices = await axios.get(url, {
    //     params: parameters
    //   });
    //   return invoices;
    // } else {
    const url = `${SUBLET_INVOICE_PATH}${SEARCH_PATH}/${encodeURIComponent(parameters.category)}`;
    const invoices = await axios.get(url);
    return invoices;
    // }
  }
  async fetchSubletInvoice(parameters) {
    const url = `${SUBLET_INVOICE_PATH}/${encodeURIComponent(parameters.docId)}`;
    const invoice = await axios.get(url);
    return invoice;
  }
  async saveSubletInvoice(parameters) {
    const url = `${SUBLET_INVOICE_PATH}${SAVE_PATH}`;
    await axios.put(url, parameters);
  }
  async approveSubletInvoice(parameters) {
    const url = `${SUBLET_INVOICE_PATH}${APPROVE_PATH}`;
    await axios.put(url, parameters);
  }
  async rejectSubletInvoice(parameters) {
    const url = `${SUBLET_INVOICE_PATH}${REJECT_PATH}`;
    await axios.put(url, parameters);
  }
  async sendSubletInvoice(parameters) {
    const url = `${SUBLET_INVOICE_PATH}${SEND_PATH}`;
    await axios.put(url, parameters);
  }
  async validateFullSubletInvoice(parameters) {
    const url = `${SUBLET_INVOICE_PATH}${VALIDATE_PATH}/full`;
    await axios.put(url, parameters);
  }
  async validateHeaderSubletInvoice(parameters) {
    const url = `${SUBLET_INVOICE_PATH}${VALIDATE_PATH}/header`;
    await axios.put(url, parameters);
  }
  async fetchDownload(parameters) {
    const url = `${SUBLET_INVOICE_PATH}${DOWNLOAD_PATH}/${encodeURIComponent(parameters.objectId)}`;
    return await axios.get(url, {
      responseType: 'blob'
    });
  }
  async getSubletInvoicesCount(parameters) {
    const url = `${SUBLET_INVOICE_PATH}${INVOICES_COUNT_PATH}/${encodeURIComponent(parameters)}`;
    const invoicesCount = await axios.get(url);
    return invoicesCount;
  }
}

export default new SubletInvoiceService(); // singleton object
