export const getDefaultState = () => {
  return {
    invoices: {},
    historyInvoices: [],
    reviewSubletInvoices: [],
    reassignInvoices: [],
    invoice: {},
    dbInvoice: {},
    apProcessors: [],
    divisionAPProcessors: [],
    divisionApprovers: [],
    taxJurisdictions: [],
    invoiceObjects: [],
    vendorsList: [],
    filteredInvoices: [],
    pos: [],
    firePOs: [],
    accounts: [],
    loadingModal: null,
    fetchingPOs: null,
    appEUrl: null,
    oldVendorId: null,
    isFreezePdf: false,
    defaultDate: 30,
    isDuplicateInvoice: false,
    selectedFirePO: {},
    firePOItems: [],
    day: null,
    month: null,
    year: null,
    invoicesCount: null
  };
};
export const DEFAULT_ACCOUNT_NUMBER = '1009030003000';
export default {
  ...getDefaultState()
};
