import namespaceHelper from '@/shared/helpers/namespace-helper';

import { namespace, SubletInvoiceGetters } from './types';

const _getters = namespaceHelper.removeNamespace(namespace, SubletInvoiceGetters);

export default {
  [_getters.GET_FIRE_INVOICE_UI_CONFIG]: state => {
    return state.uiConfig;
  },
  [_getters.GET_DEFAULT_DATE]: state => {
    return state.defaultDate;
  },
  [_getters.GET_INVOICES]: state => invoiceType => {
    return state.invoices[invoiceType];
  },
  [_getters.GET_HISTORY_INVOICES]: state => {
    return state.historyInvoices ? state.historyInvoices : [];
  },
  [_getters.GET_REVIEW_SUBLET_INVOICES]: state => {
    return state.invoices.review ? state.invoices.review : [];
  },
  [_getters.GET_REASSIGN_INVOICES]: state => {
    return state.reassignInvoices ? state.reassignInvoices : [];
  },
  [_getters.GET_FILTERED_SUBLET_INVOICES]: state => {
    return state.filteredInvoices;
  },
  [_getters.GET_SUBLET_INVOICE]: state => {
    return state.invoice;
  },
  [_getters.GET_DB_SUBLET_INVOICE]: state => {
    return state.dbInvoice;
  },
  [_getters.GET_INVOICE_OBJECTS]: state => {
    return state.invoiceObjects;
  },
  [_getters.GET_AP_PROCESSORS]: state => {
    return state.apProcessors;
  },
  [_getters.GET_DIVISION_AP_PROCESSORS]: state => {
    return state.divisionAPProcessors;
  },
  [_getters.GET_TAX_JURISDICTIONS]: state => {
    return state.taxJurisdictions;
  },
  [_getters.GET_POS]: state => {
    return state.pos;
  },
  [_getters.GET_FIRE_POS]: state => {
    return state.firePOs;
  },
  [_getters.GET_ACCOUNTS]: state => {
    return state.accounts;
  },
  [_getters.GET_INVOICE_APPROVERS]: state => {
    return state.approvers;
  },
  [_getters.GET_INVOICE_DIVISION_APPROVERS]: state => {
    return state.divisionApprovers;
  },
  [_getters.GET_VENDORS]: state => {
    return state.vendorsList;
  },
  [_getters.GET_IS_LOADING_MODAL]: state => {
    return state.loadingModal;
  },
  [_getters.GET_IS_FETCHING_POS]: state => {
    return state.fetchingPOs;
  },
  [_getters.GET_IS_FREEZE_PDF]: state => {
    return state.isFreezePdf;
  },
  [_getters.GET_APP_E_URL]: state => {
    return state.appEUrl;
  },
  [_getters.GET_DAY]: state => {
    return state.day;
  },
  [_getters.GET_MONTH]: state => {
    return state.month;
  },
  [_getters.GET_YEAR]: state => {
    return state.year;
  },
  [_getters.GET_OLD_VENDOR_ID]: state => {
    return state.oldVendorId;
  },
  [_getters.HAS_CHANGES]: state => {
    const replacer = (k, v) => (v === '' ? null : v);
    return JSON.stringify(state.invoice, replacer) !== JSON.stringify(state.dbInvoice, replacer);
  },
  [_getters.GET_IS_DUPLICATE_INVOICE]: state => {
    return state.isDuplicateInvoice;
  },
  [_getters.GET_SELECTED_FIRE_PO]: state => {
    return state.selectedFirePO;
  },
  [_getters.GET_FIRE_PO_ITEMS]: state => {
    return state.firePOItems;
  },
  [_getters.GET_INVOICES_COUNT]: state => {
    return state.invoicesCount;
  }
};
