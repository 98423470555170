export const namespace = 'invoice/';

export const InvoiceGetters = {
  GET_FIRE_INVOICE_UI_CONFIG: namespace + 'getFireInvoiceUiConfig',
  GET_DEFAULT_DATE: namespace + 'getDefaultDate',
  GET_INVOICES: namespace + 'getInvoices',
  GET_HISTORY_INVOICES: namespace + 'getHistoryInvoices',
  GET_REVIEW_INVOICES: namespace + 'getReviewInvoices',
  GET_REASSIGN_INVOICES: namespace + 'getReassignInvoices',
  GET_FILTERED_INVOICES: namespace + 'getFilteredInvoices',
  GET_INVOICE: namespace + 'getInvoice',
  GET_DB_INVOICE: namespace + 'getDBInvoice',
  GET_INVOICE_OBJECTS: namespace + 'getInvoiceObjects',
  GET_AP_PROCESSORS: namespace + 'getAPProcessors',
  GET_DIVISION_AP_PROCESSORS: namespace + 'getDivisionAPProcessors',
  GET_INVOICE_APPROVERS: namespace + 'getInvoiceApprovers',
  GET_INVOICE_DIVISION_APPROVERS: namespace + 'getInvoiceDivisionApprovers',
  GET_TAX_JURISDICTIONS: namespace + 'getTaxJurisdictions',
  GET_VENDORS: namespace + 'getVendors',
  GET_POS: namespace + 'getPOs',
  GET_ACCOUNTS: namespace + 'getAccounts',
  GET_IS_LOADING_MODAL: namespace + 'getLoadingModal',
  GET_IS_FETCHING_POS: namespace + 'getIsFetchingPOs',
  GET_IS_FREEZE_PDF: namespace + 'isFreezePdf',
  GET_APP_E_URL: namespace + 'getAppEUrl',
  GET_DAY: namespace + 'getDay',
  GET_MONTH: namespace + 'getMonth',
  GET_YEAR: namespace + 'getYear',
  GET_OLD_VENDOR_ID: namespace + 'getOldVendorId',
  HAS_CHANGES: namespace + 'hasChanges',
  GET_IS_DUPLICATE_INVOICE: namespace + 'getIsDuplicateInvoice',
  GET_FIRE_POS: namespace + 'getFirePOs',
  GET_SELECTED_FIRE_PO: namespace + 'getSelectedFirePO',
  GET_FIRE_PO_ITEMS: namespace + 'getFirePOItems',
  GET_INVOICES_COUNT: namespace + 'getInvoicesCount',
  GET_DEFAULT_GL_ACCOUNT: namespace + 'getDefaultGLAccount'
};

export const InvoiceActions = {
  FETCH_FIRE_INVOICE_UI_CONFIG: namespace + 'fetchFireInvoiceUiConfig',
  SEARCH_INVOICES: namespace + 'searchInvoices',
  FETCH_INVOICE: namespace + 'searchInvoice',
  FETCH_AP_PROCESSORS: namespace + 'fetchAPProcessors',
  FETCH_DIVISION_AP_PROCESSORS: namespace + 'fetchDivisionAPProcessors',
  FETCH_INVOICE_APPROVERS: namespace + 'fetchInvoiceApprovers',
  FETCH_INVOICE_DIVISION_APPROVERS: namespace + 'fetchInvoiceDivisionApprovers',
  FETCH_TAX_JURISDICTIONS: namespace + 'fetchTaxJurisdictions',
  SEARCH_APPROVERS: namespace + 'searchApprovers',
  SEARCH_VENDORS: namespace + 'searchVendors',
  SEARCH_ACCOUNTS: namespace + 'searchAccounts',
  FETCH_POS: namespace + 'fetchPOs',
  SAVE_INVOICE: namespace + 'saveInvoice',
  APPROVE_INVOICE: namespace + 'approveInvoice',
  REJECT_INVOICE: namespace + 'rejectInvoice',
  REQUEST_INVOICE_APPROVAL: namespace + 'requestInvoiceApproval',
  SEND_INVOICE: namespace + 'sendInvoice',
  VOUCHER_INVOICE: namespace + 'voucherInvoice',
  ADD_NEW_LINE_ITEM: namespace + 'addNewLineItem',
  REMOVE_LINE_ITEM: namespace + 'removeLineItem',
  REMOVE_INVOICE: namespace + 'removeInvoice',
  VALIDATE_FULL_INVOICE: namespace + 'validateFullInvoice',
  VALIDATE_HEADER_INVOICE: namespace + 'validateHeaderInvoice',
  FETCH_APP_E_URL: namespace + 'fetchAppEUrl',
  DOWNLOAD_PDF: namespace + 'downloadPDF',
  FETCH_FIRE_POS: namespace + 'fetchFirePOs',
  RUN_DATA_SYNC: namespace + 'runDataSync',
  FETCH_DATA_SYNC: namespace + 'fetchDataSync',
  PUT_REASSIGN_INVOICES: namespace + 'putReassignInvoices',
  FETCH_INVOICES_COUNT: namespace + 'fetchInvoicesCount'
};

export const InvoiceMutations = {
  SET_FIRE_INVOICE_UI_CONFIG: namespace + 'setFireInvoiceUiConfig',
  RESET_STATE: namespace + 'resetState',
  SET_INVOICES: namespace + 'setInvoices',
  SET_HISTORY_INVOICES: namespace + 'setHistoryInvoices',
  SET_REVIEW_INVOICES: namespace + 'setReviewInvoices',
  SET_REASSIGN_INVOICES: namespace + 'setReassignInvoices',
  SET_INVOICE: namespace + 'setInvoice',
  SET_DB_INVOICE: namespace + 'setDBInvoice',
  SET_FILTERED_INVOICES: namespace + 'setFilteredInvoices',
  SET_INVOICE_OBJECTS: namespace + 'setInvoiceObjects',
  CLEAR_INVOICE_OBJECTS: namespace + 'clearInvoiceObjects',
  SET_AP_PROCESSORS: namespace + 'setAPProcessors',
  SET_DIVISION_AP_PROCESSORS: namespace + 'setDivisionAPProcessors',
  SET_INVOICE_APPROVERS: namespace + 'setInvoiceApprovers',
  SET_INVOICE_DIVISION_APPROVERS: namespace + 'setInvoiceDivisionApprovers',
  SET_TAX_JURISDICTIONS: namespace + 'setTaxJurisdictions',
  SET_VENDORS_LIST: namespace + 'setVendorsList',
  SET_POS: namespace + 'setPOs',
  SET_COMMON_PROP: namespace + 'setCommonProp',
  SET_LINEITEM_PROP: namespace + 'setLineItemProp',
  SET_DB_LINEITEM_PROP: namespace + 'setDBLineItemProp',
  SET_DEFAULT_GL_ACCOUNT: namespace + 'setDefaultGLAccount',
  ADD_ORIGINAL_VENDOR: namespace + 'addOriginalVendor',
  REMOVE_INVOICE: namespace + 'removeInvoice',
  IS_LOADING_MODAL: namespace + 'isLoadingModal',
  IS_FETCHING_POS: namespace + 'isFetchingPOs',
  SET_NEW_LINE_ITEM: namespace + 'setNewLineItem',
  REMOVE_LINE_ITEM: namespace + 'removeLineItem',
  SET_OLD_VENDOR_ID: namespace + 'setOldVendorId',
  UPDATE_LINE_ITEM_NUMBERS: namespace + 'updateLineItemNumbers',
  SET_APP_E_URL: namespace + 'setAppEUrl',
  SET_DAY: namespace + 'setDay',
  SET_MONTH: namespace + 'setMonth',
  SET_YEAR: namespace + 'setYear',
  IS_FREEZE_PDF: namespace + 'isFreezePdf',
  SET_IS_DUPLICATE_INVOICE: namespace + 'setIsDuplicateInvoice',
  SET_SELECTED_FIRE_PO: namespace + 'setSelectedFirePO',
  SET_FIRE_PO_ITEMS: namespace + 'setFirePOItems',
  SET_FIRE_POS: namespace + 'setFirePOs',
  SET_FIRE_PO_LINEITEM_PROP: namespace + 'setFirePOLineItemProp',
  SET_INVOICES_COUNT: namespace + 'setInvoicesCount'
};
